<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 text-uppercase">
        <router-link :to="{ name: 'Logs' }">
          <v-icon class="cursor--pointer">mdi-reply</v-icon>
        </router-link>
        {{ $t("Log Details") }}
      </div>
    </div>
    <v-alert v-model="errorAlert" dismissible dense outlined type="error">
      <span> {{ errorMsg }} </span>
    </v-alert>
    <v-card>
      <v-container>
        <v-row>
          <v-col><strong> Uuid: </strong> {{ log ? log.uuid : "" }} </v-col>
          <v-col
            ><strong> Date: </strong>
            {{ log ? prettyDate(log.createAt) : "" }}</v-col
          >
          <v-col><strong> Path: </strong> {{ log ? log.path : "" }}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row>
          <v-col
            ><strong> Type: </strong>
            <span :class="`${colortype}--text`">{{ log ? log.type : "" }}</span>
          </v-col>
          <v-col><strong> Method: </strong> {{ log ? log.method : "" }} </v-col>
          <v-col
            ><strong> Description: </strong> {{ log ? log.description : "" }}
          </v-col>
        </v-row>

        <template v-if="createdBy">
          <v-divider class="my-3"></v-divider>
          <v-row>
            <v-col
              ><strong> Name: </strong> {{ log ? log.createdBy.fullname : "" }}
            </v-col>
            <v-col
              ><strong> Email: </strong> {{ log ? log.createdBy.email : "" }}
            </v-col>
            <v-col
              ><strong> Rol: </strong> {{ log ? log.createdBy.rol : "" }}
            </v-col>
          </v-row>
        </template>

        <template v-if="log != null && haveQuerys">
          <v-divider class="my-3"></v-divider>
          <span><strong>Queries:</strong> </span>
          <v-row>
            <v-col>
              {{ log.queries }}
            </v-col>
          </v-row>
        </template>
        <template v-if="log != null && haveParams">
          <v-divider class="my-3"></v-divider>
          <span><strong>Params:</strong> </span>
          <v-row>
            <v-col>
              {{ log.params }}
            </v-col>
          </v-row>
        </template>
        <template v-if="log != null && haveHeaders">
          <v-divider class="my-3"></v-divider>
          <span><strong>Headers:</strong> </span>
          <v-row>
            <v-col>
              {{ log.headers }}
            </v-col>
          </v-row>
        </template>
        <template v-if="log != null && haveBody">
          <v-divider class="my-3"></v-divider>
          <span><strong>Body:</strong> </span>
          <v-row>
            <v-col>
              {{ log.body }}
            </v-col>
          </v-row>
        </template>
        <template
          v-if="log != null && log.procedure != {} && log.procedure != null"
        >
          <v-divider class="my-3"></v-divider>
          <span><strong>Procedure:</strong> </span>
          <v-row>
            <v-col>
              {{ log.procedure }}
            </v-col>
          </v-row>
        </template>
        <template v-if="haveResponse">
          <v-divider class="my-3"></v-divider>
          <span><strong>Response:</strong> </span>
          <v-row>
            <v-col>
              {{ log.response }}
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import moment from "moment";
import { isEmpty } from "lodash";
export default {
  name: "view-log",
  data() {
    return {
      loading: false,
      log: null,
      errorAlert: false,
      errorMsg: "",
    };
  },
  computed: {
    colortype() {
      if (this.log == null) {
        return "black";
      }
      if (this.log.type == "ERROR") {
        return "red";
      }

      return "green";
    },
    haveBody() {
      if (this.log == null) {
        return false;
      }
      if (isEmpty(this.log.body)) {
        return false;
      }
      return true;
    },
    haveHeaders() {
      if (this.log == null) {
        return false;
      }
      if (isEmpty(this.log.headers)) {
        return false;
      }
      return true;
    },
    createdBy() {
      if (this.log == null) {
        return false;
      }
      if (this.log.createdBy == null) {
        return false;
      }
      if (isEmpty(this.log.createdBy)) {
        return false;
      }
      return true;
    },
    haveParams() {
      if (this.log == null) {
        return false;
      }
      if (isEmpty(this.log.params)) {
        return false;
      }
      return true;
    },
    haveResponse() {
      if (this.log == null) {
        return false;
      }
      if (isEmpty(this.log.response)) {
        return false;
      }
      return true;
    },
    haveQuerys() {
      if (this.log == null) {
        return false;
      }
      if (isEmpty(this.log.queries)) {
        return false;
      }
      return true;
    },
  },
  methods: {
    prettyDate(date) {
      if (date === null || date === "") return "";
      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    getLog() {
      const uuid = this.$route.params.uuid;
      this.loading = true;
      getAPI
        .get("/sys-logs/" + uuid)
        .then((res) => {
          this.log = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const msg = error.response.data
            ? error.response.data.message.toString()
            : error.message;
          this.errorAlert = true;
          this.errorMsg = msg;
        });
    },
  },
  mounted() {
    this.getLog();
  },
};
</script>
<style lang="scss" scoped></style>
